    import { useCallback} from 'react';
    import '../Styles/distributor-root.css';
    import {
        Outlet,
        Link,
        useNavigate
    } from "react-router-dom";
    

const data = [
    {path:'/dashboard', title:'Dashboard', icon:<svg class="w-5 h-5 text-amber-500 transition duration-75 dark:text-amber-400 group-hover:text-gray-900" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"> <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 15v4m6-6v6m6-4v4m6-6v6M3 11l6-5 6 5 5.5-5.5"/> </svg>},
    {path:'/artists', title:'Artistas', icon:<svg class="w-5 h-5 text-amber-500 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"> <path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="M4.5 17H4a1 1 0 0 1-1-1 3 3 0 0 1 3-3h1m0-3.05A2.5 2.5 0 1 1 9 5.5M19.5 17h.5a1 1 0 0 0 1-1 3 3 0 0 0-3-3h-1m0-3.05a2.5 2.5 0 1 0-2-4.45m.5 13.5h-7a1 1 0 0 1-1-1 3 3 0 0 1 3-3h3a3 3 0 0 1 3 3 1 1 0 0 1-1 1Zm-1-9.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z"/> </svg>},
    {path:'/albums', title:'Albums', icon: <svg class="w-5 h-5 text-amber-500 transition duration-75 dark:text-amber-400 group-hover:text-gray-900" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"> <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 11h2v5m-2 0h4m-2.592-8.5h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/> </svg>},
    {path:'/playlists', title:'Playlists', icon:<svg class="w-5 h-5 text-amber-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"> <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 4H5a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1Zm0 0-4 4m5 0H4m1 0 4-4m1 4 4-4m-4 7v6l4-3-4-3Z"/> </svg> },
    {path:'/upload', title:'Carregar musica', icon:<svg class="w-5 h-5 text-amber-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"> <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 16H5a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v1M9 12H4m8 8V9h8v11h-8Zm0 0H9m8-4a1 1 0 1 0-2 0 1 1 0 0 0 2 0Z"/> </svg> },
    {path:'/settings', title:'Definições', icon: <svg class="w-5 h-5 text-amber-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"> <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 5 9 4V3m5 2 1-1V3m-3 6v11m0-11a5 5 0 0 1 5 5m-5-5a5 5 0 0 0-5 5m5-5a4.959 4.959 0 0 1 2.973 1H15V8a3 3 0 0 0-6 0v2h.027A4.959 4.959 0 0 1 12 9Zm-5 5H5m2 0v2a5 5 0 0 0 10 0v-2m2.025 0H17m-9.975 4H6a1 1 0 0 0-1 1v2m12-3h1.025a1 1 0 0 1 1 1v2M16 11h1a1 1 0 0 0 1-1V8m-9.975 3H7a1 1 0 0 1-1-1V8"/> </svg> },
];
    export default function DistributorRoot(){
        const navigate = useNavigate();

        const handleLogout = useCallback(()=>{
            localStorage.clear();
           navigate("/");
           window.location.reload();
            
        });

        return (
            <>
                <aside id="default-sidebar" class="fixed top-0 left-0 z-40 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0" aria-label="Sidebar">
                    <div class="h-full px-3 py-4 overflow-y-auto bg-black">
                        <ul class="space-y-2 font-medium">
                        {data.map((e, i)=>{
                            return(<li>
                                        <Link to={e.path}>
                                            <span class="flex items-center p-2 text-gray-900 rounded-lg hover:border-amber-500 group">
                                                {e.icon}
                                                <span class="ms-3 text-amber-500">{e.title}</span>
                                            </span>
                                        </Link>
                                    </li>)
                            })
                        }<li>
                        
                            <button onClick={()=>handleLogout()} class="flex items-center p-2 text-gray-900 rounded-lg hover:border-amber-500 group">
                            
                                <span class="ms-3 text-amber-500">Sair</span>
                            </button>
                            
                        </li>
                        </ul>
                    </div>
                </aside>
                        
                <div class="p-4 sm:ml-64 h-full overflow-y-auto">
                    <div class="p-4flex-1 h-full">
                        <Outlet/>
                    </div>
                </div>
            </>
        );

   
    }