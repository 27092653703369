import { useState, useEffect, useCallback } from 'react';
import '../../Styles/ArtistRoot.css';

import COUNTRIES from '../../utils/Countries';
import GENRES from '../../utils/Genres';

import {AiOutlinePlusCircle} from 'react-icons/ai';
import {BsFileEarmarkImage} from 'react-icons/bs';

import {BsFileEarmarkMusic} from 'react-icons/bs';
import { Formik, Field, useFormik} from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import izenuAPI from '../../services/izenuAPI';
const uuid = () =>{

    const alfaNumeric = '1234567890ABCDEFGHIJKLMNOPQRSTUVXWYZabcdefghijklmnopkrstuvxwyz';
    let str = alfaNumeric.split("");
    let str2 = '';
    for(var i = 0; i < 8; i++){
        str2 += '' + str[Math.ceil(Math.random() * str.length)];
    }
    return str2;
}
const ARTISTS  = [
    {name:'Etiene Adriano', user_id:1},
    {name:'Cristina Adriano', user_id:2},
    {name:'Adriano de Carvalho', user_id:3},
    {name:'Elizetrina Pinto', user_id:4},
    {name:'Adriana Adriano', user_id:5},
    {name:'Rafael Simão', user_id:6},
    {name:'Chayene Simão', user_id:7},
    {name:'Simone Simão', user_id:8},
]

const id = localStorage.getItem('id');
export default function Upload(){

    const [image, setImage] = useState(null);
    const [song, setSong] = useState(null);
    const [artists, setArtists] = useState([]);
    const [load, setLoad] = useState(true); 
    const [selectedUser, setSelectedUser] = useState(null);
    const [uploadingMusic, setUploadingMusic] = useState(false);
    const fetchDistributorArtists = useCallback(async()=>{
            const {data:artists} = await izenuAPI.get('https://www.izenu.ao/api/distributor/artist/fetchArtists.php?distributor_id=' + id);
            setArtists(artists.artists);
            setLoad(false);
    });
    useEffect(()=>{
        fetchDistributorArtists();
    },[]);

    const insertMusic = useCallback(async (data, cover_name, song_name)=>{
        data.user_id = data.user_id;
        data.music_file = song_name;
        data.cover = cover_name;

        try{
            const {data: r} = await izenuAPI.post('https://www.izenu.ao/api/distributor/artist/insertMusic.php', data);
            
            if(r.response){
                alert("Música carregada com sucesso!");
            }else{
                alert("Impossivel carregar música, tente mais tarde!");
            }    
        }catch(error){
            console.log(error);
        }
        
    });

    //Upload music and file
    const uploadFilesToS3 = useCallback(async (data) =>{
        setUploadingMusic(true);

        
        const id = uuid();
        const d = new Date();
        const COVER_ENDPOINT = 'https://server.izenu.net/songs/songs/upload-cover';
        const SONG_ENDPOINT = 'https://server.izenu.net/songs/songs/upload-song';

        const song_arr = song.name.split('.');
        

        const date = {day: d.getUTCDate(), year: d.getUTCFullYear(), month: d.getUTCMonth() + 1};
        

        const cover_name = `izenu_cover_${date.year}_${date.month}_${date.day}_${id}.${image.file.type.split('/')[1]}`;
        const song_name = `izenu_song_${date.year}_${date.month}_${date.day}_${id}.${song_arr[song_arr.length - 1]}`;
        const cover_prefix = `users/${data.user_id}/songs/artworks/`;
        const song_prefix = `users/${data.user_id}/songs/`;
        const cover_data = new FormData();

        cover_data.append(`files`, image.file);
        cover_data.append(`key`, cover_prefix + cover_name);
        
        const music_data = new FormData();
        music_data.append(`files`, song);
        music_data.append(`key`, song_prefix + song_name);
        

        try{
            const {data:r} = await axios.post(COVER_ENDPOINT, cover_data,
            {
                headers: {
                        'Content-Type': 'multipart/form-data',
                }
            });
            
            const {data:resp} = await axios.post(SONG_ENDPOINT, music_data);

            insertMusic(data, cover_name, song_name);

            alert("Música carregada com sucesso!");
            setUploadingMusic(false);

            //Save data into database
        }catch(error){
            alert("Erro ao carregar musica, tente mais tarde!");
            console.log('Error uploading');
            setUploadingMusic(false);

        }
        setUploadingMusic(false);

        

    },[image, song]);

    const handleCoverChange =  useCallback((event)=>{
            
        const file = event.currentTarget.files[0];
        
        const types = ['png','jpg', 'jpeg'];

        //alert(types.indexOf(file.name.split('.')[1].toLowerCase()));
        
        if(types.indexOf(file.name.split('.').slice(-1)[0].toLowerCase()) == -1){
            alert('Tipo de ficheiro invalido ');
            console.log(file);
            return;
        }

        const reader = new FileReader();
        
        reader.onload = ()=>{
            setImage({file:file, result:reader.result});
        }

        reader.readAsDataURL(file);
    
    },[image]);

    const handleMusicChange =  useCallback((event)=>{
        const file = event.currentTarget.files[0];
        console.log(file);
        let arr =  file.name.split('.');
        let type = arr.pop();
        if(type != 'mp3'){
            alert('Tipo de ficheiro invalido');
            setSong(null);
        }else{
            setSong(file);    
        }
    },[song]);
    if(uploadingMusic){
        return (<div className="justify-center block items-center flex flex-col h-full flex-1 p-5 rounded-lg" style={{height:'100%'}}>
                    <div role="status">
                        <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                        </svg>
                        <h1 className="text-amber-500 font-light text-3xl">A carregar música...</h1>
                    </div>
                </div>)
    }

    return (<div className="p-10">
       
        <Formik 
            initialValues={{
                title:'',
                tags:'',
                genre: '',
                country:'',
                link: '',
                
                user_id:'',                         
    
            }}
            
            validate={values => {
                            
                const errors = {};
                
                if (image == null) {
                    errors.image = 'A capa da música é obrigatoria';
                }

                if (song == null) {
                    errors.song = 'A ainda não carregou o música';                                    
                }

                return errors;
    
            }}
        
            onSubmit={(values, { setSubmitting, resetForm }) =>{
                //console.log('submit');
                
                //alert(JSON.stringify(values, null, 2));
                uploadFilesToS3(values);
                resetForm({values:''});
                //insertMusic(values);
            }}
            validationSchema={Yup.object({
                title:Yup.string().max(100, 'No maximo 13 caracter').min(1, 'No mínimo 1 caracter').required('Campo obrigatorio'),
            })}
        
            >
            {({values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting}) => (
                <>
                 <div className="p-10 flex flex-row space-x-4">
                    <div className="relative flex-1 gap-2 border py-5 rounded-lg">
                        <h1 className="font-bold">
                            Capa e música
                        </h1>
                    </div>
                    <div className="relative flex-1 gap-2 border py-5 rounded-lg">
                        <h1 className="font-bold">
                            Metadados
                        </h1>
                    </div>
                </div>
                <form onSubmit={(e)=>{e.preventDefault(); handleSubmit();}}>
                    <div className="flex flex-col">
                        <div className="p-10 flex flex-row space-x-4">

                            <div className="relative border rounded-lg flex-1 max-h-96 overflow-y-auto gap-2  p-5">
                                
                                <label for="input-group-1" class="block mb-2 text-sm font-medium text-gray-900 text-left">Música</label>
                                <div className='relative mb-6'>
                                    <input onChange={handleMusicChange} id="song" name="song" type="file" placeholder='Musica' class="block w-full text-sm focus:border-amber-500 text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" aria-describedby="user_avatar_help"/>
                                    <div class="mt-1 text-sm text-gray-500 text-left dark:text-gray-300" id="user_avatar_help">Apenas apenas ficheiro do tipo mp3 e wave</div>
                                </div>

                                <label for="input-group-1" class="block mb-2 text-sm font-medium text-gray-900 focus:border-amber-500 text-left">Capa</label>
                                <div className='relative mb-6'>
                                    <input onChange={handleCoverChange} id="image" name="image" type="file" placeholder='Capa da música' class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" aria-describedby="user_avatar_help"/>
                                    <div class="mt-1 text-sm text-gray-500 text-left dark:text-gray-300" id="user_avatar_help">As capas devem ser do formato png, e jpg inferiores a 2Mb</div>
                                </div>
                            
                            </div>

                            <div className="relative flex-1 gap-2 border p-5 rounded-lg">
                                
                                    <label for="input-group-1" class="block mb-2 text-sm font-medium text-gray-900 text-left">Titulo da musica</label>
                                    {errors.title && touched.title ? (<span className='alert-error'>{errors.title}</span>) : null}
                                    <div class="relative mb-6">
                                            <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                                                <svg class="w-4 h-4 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                                    <path fill-rule="evenodd" d="M9 7V2.221a2 2 0 0 0-.5.365L4.586 6.5a2 2 0 0 0-.365.5H9Zm2 0V2h7a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V9h5a2 2 0 0 0 2-2Zm2.318.052h-.002A1 1 0 0 0 12 8v5.293A4.033 4.033 0 0 0 10.5 13C8.787 13 7 14.146 7 16s1.787 3 3.5 3 3.5-1.146 3.5-3c0-.107-.006-.211-.017-.313A1.04 1.04 0 0 0 14 15.5V9.766c.538.493 1 1.204 1 2.234a1 1 0 1 0 2 0c0-1.881-.956-3.14-1.86-3.893a6.4 6.4 0 0 0-1.636-.985 4.009 4.009 0 0 0-.165-.063l-.014-.005-.005-.001-.002-.001ZM9 16c0-.356.452-1 1.5-1s1.5.644 1.5 1-.452 1-1.5 1S9 16.356 9 16Z" clip-rule="evenodd"/>
                                                </svg>

                                            </div>
                                            <input name="title" onChange={handleChange} onBlur={handleBlur} value={values.title} type="text" placeholder="Titulo da musica" className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-amber-500 focus:border-amber-500 block w-full ps-10 p-2.5" />
                                    </div>

                                    <label for="artists" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Selecionar o artista</label>
                                    {errors.user_id && touched.user_id ? (<span className='alert-error'>{errors.user_id}</span>) : null}
                                    <div class="relative mb-6">
                                        <select 
                                            id="artists" 
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-amber-500 focus:border-amber-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-amber-500 dark:focus:border-amber-500"
                                            onBlur={handleBlur} 
                                            
                                            name="user_id"
                                            value={selectedUser} // ...force the select's value to match the state variable...
                                            onChange={handleChange}>
                                                <option value={null}>Selecionar</option>
                                                    {artists.map((e,i)=>{
                                                        
                                                        return i==0?(<option key={JSON.stringify(i)} value={e.user_id}>{e.name}</option>):(<option key={JSON.stringify(i)} value={e.user_id}>{e.name}</option>);
                                                    })}
                                                                                    
                                        </select>
                                    </div>
                                    
                                    <label for="tags" class="block mb-2 text-sm font-medium text-gray-900 text-left">Tags</label>
                                    <div class="relative mb-6">
                                        <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                                            <svg class="w-4 h-4 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                                <path fill-rule="evenodd" d="M9 7V2.221a2 2 0 0 0-.5.365L4.586 6.5a2 2 0 0 0-.365.5H9Zm2 0V2h7a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V9h5a2 2 0 0 0 2-2Zm2.318.052h-.002A1 1 0 0 0 12 8v5.293A4.033 4.033 0 0 0 10.5 13C8.787 13 7 14.146 7 16s1.787 3 3.5 3 3.5-1.146 3.5-3c0-.107-.006-.211-.017-.313A1.04 1.04 0 0 0 14 15.5V9.766c.538.493 1 1.204 1 2.234a1 1 0 1 0 2 0c0-1.881-.956-3.14-1.86-3.893a6.4 6.4 0 0 0-1.636-.985 4.009 4.009 0 0 0-.165-.063l-.014-.005-.005-.001-.002-.001ZM9 16c0-.356.452-1 1.5-1s1.5.644 1.5 1-.452 1-1.5 1S9 16.356 9 16Z" clip-rule="evenodd"/>
                                            </svg>
                                        </div>
                                        <input name="tags" onChange={handleChange} onBlur={handleBlur} value={values.tags} type="text" placeholder="Adicionar tags que descrevem a música genero" className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-amber-500 focus:border-amber-500 block w-full ps-10 p-2.5" />
                                    </div>
                                    
                                    <label for="countries" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Genero</label>
                                    <div class="relative mb-6">
                                    
                                        <select onChange={handleChange} onBlur={handleBlur} value={values.genre} name="genre" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-amber-500 focus:border-amber-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-amber-500 dark:focus:border-amber-500">
                                            {GENRES.map((e,i)=>{
                                                
                                                return i==0?(<option  default key={JSON.stringify(i)} value={e.name}>{e.name}</option>):(<option key={JSON.stringify(i)} value={e.name}>{e.name}</option>);
                                            })}
                                        </select>
                                    </div>

                                    <label for="countries" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">País</label>
                                    <div class="relative mb-6">
                                        <select onChange={handleChange} onBlur={handleBlur} value={values.country} name="country" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-amber-500 focus:border-amber-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-amber-500 dark:focus:border-amber-500">
                                            {COUNTRIES.map((e,i)=>{    
                                                return i==0?(<option  default key={JSON.stringify(i)} value={e.name}>{e.name}</option>):(<option key={JSON.stringify(i)} value={e.name}>{e.name}</option>);
                                            })}
                                        </select>
                                    </div>
                                    <label for="input-group-1" class="block mb-2 text-sm font-medium text-gray-900 text-left">Link</label>
                                    {errors.link && touched.link ? (<span className='alert-error'>{errors.link}</span>) : null}
                                    <div class="relative mb-6">
                                        <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                                            <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.213 9.787a3.391 3.391 0 0 0-4.795 0l-3.425 3.426a3.39 3.39 0 0 0 4.795 4.794l.321-.304m-.321-4.49a3.39 3.39 0 0 0 4.795 0l3.424-3.426a3.39 3.39 0 0 0-4.794-4.795l-1.028.961"/>
                                            </svg>
                                        </div>
                                        <input name="link" onChange={handleChange} onBlur={handleBlur} value={values.link} type="text" placeholder="Adicionar link" className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-amber-500 focus:border-amber-500 block w-full ps-10 p-2.5" />
                                    </div>
                                    
                            
                            </div>
                        </div>
                        <div className='p-10 flex'>
                            <button type="submit"  class="h-full w-full text-sm p-2 px-20 font-medium bg-amber-500 rounded">
                                <span className="text-white">
                                    Fazer upload
                                </span>
                            </button>   
                        </div>
                    </div>
                </form>
         </>                     
        )}
        </Formik>

        
    </div>);

   
}