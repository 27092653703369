
import '../../Styles/App.css';
import '../../Styles/Admin.css';
import { useCallback, useEffect, useState } from 'react';

import { Formik } from 'formik';

import axios from "axios";

const token = localStorage.getItem('token');
const izenuApi = axios.create();

izenuApi.defaults.headers.Authorization = 'Bearer ' + token;



const admin_id   = localStorage.getItem('id');
const admin_name = localStorage.getItem('name');

export default function ProfileReports(){

    const [profiles, setProfiles] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [user, setUser] = useState([]);

    const logout = useCallback(()=>{
        localStorage.clear();
        window.location.href = '/home';
       //console.log(localStorage.get('user'));
    });

    const fetchUsers = useCallback(async ()=>{
        try{    
            const r = await izenuApi.get('https://www.izenu.ao/api/report/userReports.php');
            
            setProfiles(r.data.response);
            //console.log(r.data);
        }catch(err){
            console.log(err);
        };
        //https://img.gs/pqgzqcgmgv/quality=low/https://dpagz47n4g1lw.cloudfront.net/users/".$value['author_id']."/posts/".$folder."/".$file['file']
    });
    const blockUser = useCallback(async (data)=>{
        try{    
            izenuApi.post('https://www.izenu.ao/api/block/adminBlockUser.php', {
                user_id: data.user_id,
                admin_id: data.admin_id,
                admin_note: data.admin_note,
                report_id: data.report_id
            }).then((r)=>{
                //console.log(r.data);
            }).catch((error)=>{
                console.log(error);
            });
            //Remove modal
            setShowModal(false);
        }catch(err){
            console.log(err);
        };
        //https://img.gs/pqgzqcgmgv/quality=low/https://dpagz47n4g1lw.cloudfront.net/users/".$value['author_id']."/posts/".$folder."/".$file['file']
    },[user]);
    useEffect(()=>{
        fetchUsers();    
    },[]);
    return (<div>
              
                   
                    <div style={{padding:40, display:'flex', alignItems:'center', justifyContent:'center'}}>
                        <div className="admin-content">               
                            <div className="profiles-content">
                            {profiles.map((e,i)=>{
                                    const photo = `https://dpagz47n4g1lw.cloudfront.net/users/${e.user_id}/profile_photos/${e.photo}`;
                                    return(
                                        <div key={i} className="user-content-container">
                                            <div className="user-photo">
                                                {(e.photo == ''|| e.photo == undefined)?(
                                                    <></>
                                                ):(
                                                    <img className="user-photo" src={photo}/>
                                                )}
                                                
                                            </div>
                                            <div className="user-data">
                                                <div className="text-white user-name">
                                                    {e.name}
                                                </div>
                                                <div className='user-data'>
                                                    
                                                    <div className="text-white report-type">
                                                        {e.type}
                                                    </div>
                                                </div>
                                                <button onClick={()=>{
                                                    setShowModal(true)
                                                    setUser({user_id:e.user_id, photo: photo, name: e.name})
                                                }} className="text-white report-pending">
                                                    Bloquear
                                                </button>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>

                        </div>
                    </div>
               
                {showModal?(
                    <div className="opt-modal-wrapper">
                        <div className="opt-modal-container">
                            <div className="opt-modal-content">
                                <div className="block-user-container">
                                    <div className="block-user-header">
                                        <h3>Tem certeza que deseja bloquear este utilizador?</h3>
                                    </div>
                                    <Formik
                                        initialValues={{notes:''}}
                                        validate={values=>{
                                            const errors = {};
                                            if(values.notes.length == 0){
                                                errors.notes = 'A descrição é um campo obrigatorio!'
                                            }
                                            return errors;
                                        }}
                                        onSubmit={(values, setSubmit)=>{
                                            blockUser({user_id: user.user_id, admin_id: admin_id, admin_note: values.notes, report_id:1 })
                                        }}
                                    >
                                        {({values, errors, touched, handleChange,handleBlur, handleSubmit, isSubmitting}) => (
                                        <form onSubmit={handleSubmit}>
                                            <div className="block-user-content">
                                                <div className="user-data-content">
                                                    <div className="user-data-photo">
                                                        <img className="user-data-photo" src={user.photo}/>
                                                    </div>
                                                    <div className="user-data-name">
                                                        <p className='text-white'>{user.name}</p>
                                                    </div>
                                                </div>
                                                <div className='block-user-text-container'>
                                                    <p className='text-white'>Informa  o motivo <b>obrigatório</b></p>
                                                    <p className="text-purple text-left">{errors.notes && touched.notes}</p>
                                                    <textarea name="notes" onChange={handleChange} value={values.notes} onBlur={handleBlur} placeholder='Motivo pela qual o utilizador será bloqueado' className="block-content-description" rows={4} cols={40}/>
                                                </div>
                                            </div>
                                            <div className="block-user-footer">
                                                <button onClick={()=>setShowModal(false)}>
                                                    Cancelar
                                                </button>
                                                <button type="submit" className='block'>
                                                    Bloquear
                                                </button>
                                            </div>
                                        </form>
                                            )}
                                    </Formik>                     
                                </div>
                            </div>
                        </div>
                    </div>
                ):(
                    <></>
                )}
            </div>)
}