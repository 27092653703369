import { useState, useEffect, useCallback } from 'react';
import '../../Styles/ArtistRoot.css';

import axios from 'axios';
import izenuAPI from '../../services/izenuAPI';

export default function Settings(){
    const [data, setData] = useState([]);
    const [errors, setErrors] = useState({
                                            name:null,
                                            email:null,
                                            nif: null,
                                            phone: null,
                                            description: null,
                                            address:null,
                                            iban: null,
                                        });
        const [name, setName] = useState('');
        const [email, setEmail] = useState('');
        const [nif, setNif] = useState('');
        const [phone, setPhone] = useState('');
        const [description, setDescription] = useState('');
        const [address, setAddress] = useState('');
        const [iban, setIban] = useState('');
    const fetchDistributorDetails = useCallback(async ()=>{
        const id = localStorage.getItem('id');
   
      const {data: resp} = await izenuAPI.get('https://www.izenu.ao/api/distributor/fetchDistributorData.php?id='+id);
        if(resp.response){
            const distributor = resp.distributor;
            setName(distributor.name);
            setEmail(distributor.email);
            setNif(distributor.nif);
            setPhone(distributor.phone);
            setDescription(distributor.description);
            setAddress(distributor.address);
            setIban(distributor.iban);
        }else{
            alert("Distribuidor não encontrado");
        }
    },[]);

    useEffect(()=>{
        fetchDistributorDetails();
    },[]);
    const handleErrors = useCallback((data)=>{
        if(data.name.length == 0){
            const err = errors;
            err.name = 'Campo obrigatorio';

            setErrors(err);
        }
    }, [errors]);

    const updateData =  useCallback(async(data)=>{
        const {data:r} = await axios.post('https://www.izenu.ao/api/distributor/updateDistributorData.php', data);
        if(r){
            alert("Dados actualizado com sucesso");
        }else{
            alert("Impossivel actualizar, tente mais tarde");
        }
    });
    const handleSubmit = useCallback((e)=>{

        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);
            formData.append("id", localStorage.getItem('id'));
        //handleErrors(data);
        updateData(formData);
      //alert('Submit');

    });
    
    const handleMusicCover = useCallback((cover)=>{
        if(cover == null || cover == undefined){
            return (
                <div style={{width:45, backgroundColor:"#777", height:45, borderRadius:4}}>

                </div>);
        }else{
            return (<img src={cover.photo_low_quality_url} style={{width:45, height:45, borderRadius:4}}/>);
        }
    });
    
    return( <>
                <div style={{display:'flex', flexDirection:'row'}}>   
                    <h1 style={{textAlign:'left', flex:1, marginBottom:20}}>Definições</h1>
                    
                </div>
               
                <div style={{height: '100%', justifyContent:'center', alignItems:'center'}} className="content-container">  
                    <form onSubmit={(e)=>handleSubmit(e)}>
                        <div style={{display:'flex', flexDirection:'row'}}>
                            <div style={{marginRight:9}}>
                                <div className='form-item'>
                                    <h5>Nome</h5>
                                </div>   
                                
                                <div className='form-item'>
                                    <h5>Email</h5>
                                </div>   
                                <div className='form-item'>
                                    <h5>NIF</h5>
                                </div> 
                                <div className='form-item'>
                                    <h5>Contacto</h5>
                                </div> 
                                <div className='form-item'>
                                    <h5>IBAN</h5>
                                </div> 
                                <div className='form-item'>
                                    <h5>Endereço</h5>
                                </div> 
                                <div className='form-item'>
                                    <h5>Descrição</h5>
                                </div> 
                            </div>
                            <div>
                                <div className='form-item'>
                               
                                    <input name="name" className='text-input' onChange={(e)=>setName(e.target.value)} value={name} type="text" placeholder="Nome"/>
                                </div>
                                <div className='form-item'>
                                    <input name="email" className='text-input' onChange={(e)=>setEmail(e.target.value)} value={email}type="text" placeholder="Email"/>
                                </div> 
                                <div className='form-item'>
                                
                                    <input name="nif"className='text-input' onChange={(e)=>setNif(e.target.value)} value={nif} type="text" placeholder="NIF"/>
                                </div>
                                <div className='form-item'>
                                    <input name="phone" className='text-input' onChange={(e)=>setPhone(e.target.value)} value={phone} type="text" placeholder="Contacto"/>
                                </div> 
                                <div className='form-item'>
                                    <input name="iban" className='text-input' onChange={(e)=>setIban(e.target.value)} value={iban} type="text" placeholder="IBAN"/>
                                </div>
                                <div className='form-item'>
                                    <input name="address" className='text-input' onChange={(e)=>setAddress(e.target.value)} value={address} type="text" placeholder="Endereço"/>
                                </div>
                           
                                <div className='form-item'>    
                                    <textarea name="description" className='text-input' onChange={(e)=>setDescription(e.target.value)} value={description} placeholder="Descrição"></textarea>
                                </div>
                            </div>
                            
                        </div>
                        <div>  
                            <button type="submit" className='btn-blue'>
                                Actualizar
                            </button>
                        </div>
                    </form>   
                 
                </div>
            </>); 
}