import { useState, useEffect, useCallback } from "react"


export default function Carousel({ children: slides, autoSlide = false, autoSlideInterval = 3000, data }) {
  const [curr, setCurr] = useState(0)
  const [files, setfiles] = useState([]);
  const [images, setImages] = useState([]);

  const prev = useCallback(() =>{

    setCurr((curr) => (curr === 0 ? files.length - 1 : curr - 1))
  },[curr, images]);
   
  const next = useCallback(() =>{
    console.log("Next");
    setCurr((curr) => (curr === files.length - 1 ? 0 : curr + 1))
  },[curr, images]);
    

  useEffect(() => {
    setfiles(data);
    renderFiles();
    if (!autoSlide) return
    const slideInterval = setInterval(next, autoSlideInterval)
    return () => clearInterval(slideInterval)
  }, [data]);

  const renderFiles = useCallback(()=>{

    if(files.length > 0){
      Array.prototype.forEach.call(files, readAndPreview);
    }
    
  },[files, images])

  //Preview fotos
  const readAndPreview = useCallback((file)=> {
        //console.log(file);
    // Make sure `file.name` matches our extensions criteria
    if (/\.(jpe?g|png|gif)$/i.test(file.name)) {
      const reader = new FileReader();
     // setPostFilesUrl([reader.result, ...postFilesUrl]);
     const arr =  images;
      reader.addEventListener(
        "load",
        () => {

          //files.push(String());
          arr.push(<img src={reader.result}/>);
    
        },
        false,
      );
    
       
      reader.readAsDataURL(file);
      //setImages(arr);
     

    }
},[images, files]);
  const showFiles = useCallback(()=>{
      if(images.length > 0){
        return (
          <>
            {images.map((e)=>{
              return e;
            })}
          </>
        )
      }else{
        return (<></>);
      }
    
    
  },[images, files]);

  if(images.length == 0){
    return<></>;
  }
  return (
    <div className="overflow-hidden relative">
      <div
        className="flex transition-transform ease-out duration-500"
        style={{ transform: `translateX(-${curr * 100}%)` }}
      >
        {showFiles()}
      </div>
      <div className="absolute inset-0 flex items-center justify-between p-4">
        <button
          onClick={prev}
          className="p-1 rounded-full shadow bg-white/80 text-gray-800 hover:bg-white"
        >
          <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m15 19-7-7 7-7"/>
            </svg>
        </button>
        <button
          onClick={next}
          className="p-1 rounded-full shadow bg-white/80 text-gray-800 hover:bg-white"
        >
        <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m9 5 7 7-7 7"/>
        </svg>

        </button>
      </div>

      <div className="absolute bottom-4 right-0 left-0">
        <div className="flex items-center justify-center gap-2">
          {files.map((_, i) => (
            <div
              className={`
              transition-all w-3 h-3 bg-white rounded-full
              ${curr === i ? "p-2" : "bg-opacity-50"}
            `}
            />
          ))}
        </div>
      </div>
    </div>
  )
}