
import '../../Styles/index.css';

import { useCallback,useState, useEffect } from 'react';
import {
    TERipple,
    TEModal,
    TEModalDialog,
    TEModalContent,
    TEModalHeader,
    TEModalBody,
    TEModalFooter,
  } from "tw-elements-react";

import axios from 'axios';
import { Form } from 'react-router-dom';
const token = localStorage.getItem('token');

const izenuApi = axios.create();
izenuApi.defaults.headers.Authorization = 'Bearer ' + token;

export default function Lyrics(){
    const [strSearch, setStrSearch] = useState("");
    const [results, setResults] = useState([]);
    const [response, setResponse] = useState("");
    const [lyric, setLyric] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [music, setMusic] = useState({});

    const submit = useCallback(async (e)=>{
        e.preventDefault();
        const {data: r} = await izenuApi.get(`https://www.izenu.ao/api/admin/lyric/find.php?title=${strSearch}`);
        if(r.response){
            setResults(r.musics);
        }else{
            setResponse("Não à ocorrências para " + strSearch);
        }
        setStrSearch('');
    },[strSearch, results]);

    const insert = useCallback(async ()=>{

        let m = music;
            m.admin_id = localStorage.getItem('id');
            m.lyric = lyric;
        setShowModal(false);
        setLyric("");   
        const {data: r} = await izenuApi.post(`https://www.izenu.ao/api/admin/lyric/insert.php`, m);
        if(r.response){
            alert("Letra adicionada com sucesso!");
        }else{
            alert("Impossivel adicionar letra da musica");
        }
        
    },[music, lyric]);
    return (<>
        
                    <header className="p-10">
                        <h1 className="text-3xl font-bold text-left">Adicionar letras à musicas</h1>
                    </header>
                    <div className='px-40 justify-center'>
                        <form onSubmit={submit}>
                            <div className='p-4 flex rounded-full border border-slate-400'>
                                
                                    <input onChange={(e)=>setStrSearch(e.target.value)} className="w-full bg-transparent border-0 flex-1" type='text' placeholder='Pesquisar musica...'/>
                                    <button data-modal-target="default-modal" data-modal-toggle="default-modal" className='bg-black text-xs font-medium text-slate-50 uppercase rounded-full p-2'>
                                        Pesquisar
                                    </button>    
                            </div>
                        </form>
                        <div className='pt-10'>
                            <h3 className='text-left text-slate-600 mb-10'>Resultados da pesquisa {results.length>0?results.length:<></>}</h3>
                            {setResponse!=""?<span>{response}</span>:<></>}
                            
                            <div className='mx-m'>
                                {results.length > 0 ?
                                        results.map((e,i)=>{
                                            return (
                                                <div className='w-full p-2 rounded-md shadow bg-white-50 flex mb-10'>
                                                    <div className='w-20 border-slate-400'>
                                                        <img className="rounded-md border-slate-500" src={e.cover_url.original_photo_url}/>
                                                    </div>
                                                    <div className='text-left px-3 flex flex-1 items-center justify-items-center'>
                                                        <div className='flex-1'>
                                                            <h2 className='font-bold'>{e.title}</h2>
                                                            <h2>{e.singer}</h2>
                                                        </div>
                                                        <button onClick={()=>{
                                                            setMusic(
                                                                {
                                                                    music_id: e.music_id,
                                                                    title: e.title,
                                                                    singer: e.singer
                                                                },
                                                            );
                                                            setShowModal(true);
                                                            }}>
                                                            Adicionar
                                                        </button>
                                                    </div> 
                                                </div>
                                            )
                                        })
                                    
                                :<></>}
                                    
                            </div>
                        </div>
                         
                        {/* <!-- Modal --> */}
                        <TEModal  show={showModal} setShow={setShowModal}>
                            <TEModalDialog size="lg">
                                <TEModalContent className="bg-white-50 w-full">
                                    <TEModalHeader style={{background:"#fff"}}>
                                    {/* <!--Modal title--> */}
                                    <h5 className="text-xl text-black font-medium leading-normal">
                                        Letra para {music.title}
                                    </h5>
                                    {/* <!--Close button--> */}
                                    <button
                                        type="button"
                                        className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                        aria-label="Close"
                                    >
                                        <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="currentColor"
                                        className="h-6 w-6"
                                        >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M6 18L18 6M6 6l12 12"
                                        />
                                        </svg>
                                    </button>
                                    </TEModalHeader>
                                    {/* <!--Modal body--> */}
                                    <TEModalBody style={{background:"#fff"}}>
                                        <div>
                                            <textarea onChange={(e)=>{
                                                setLyric(e.target.value);
                                                }} className='border w-full'>
                                            
                                            </textarea>
                                        </div>
                                    </TEModalBody>
                                    <TEModalFooter style={{background:"#fff"}}>
                                        <TERipple rippleColor="light">
                                            <button
                                            type="button"
                                                className="border border-gray-950 bg-gray-300 rounded bg-primary-100 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-primary-700"
                                                onClick={() => setShowModal(false)}
                                            >
                                            Fechar
                                            </button>
                                        </TERipple>
                                        <TERipple rippleColor="light">
                                            <button
                                                type="button"
                                                className="ml-1  text-slate-50 rounded bg-indigo-500 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal"
                                                onClick={()=>{
                                                    insert();
                                                }}
                                            >
                                            Salvar letra
                                            </button>
                                        </TERipple>
                                    </TEModalFooter>
                                </TEModalContent>
                            </TEModalDialog>
                        </TEModal>                    
                    </div>
                
            </>)
}