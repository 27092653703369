import { useCallback, useState, useContext, useEffect } from 'react';
import '../../Styles/Admin.css';
import logo from '../../Images/logo.png';
import logo_white  from '../../assets/logotipo_white.png'
import { Link } from 'react-router-dom';


export default function Home(){
   
 
    return (<div class="bg-black h-full">
                    <header class="absolute inset-x-0 top-0 z-50">
                        <nav class="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
                            <div class="flex lg:flex-1">
                            <a href="#" class="-m-1.5 p-1.5 flex">
                               
                                <img class="h-8 w-auto" src={logo_white} alt=""/>
                            </a>
                            </div>
                           
                          
                            <div class="lg:flex lg:flex-1 lg:justify-end">
                                <a href="/distributor" class="text-sm font-semibold leading-6 text-gray-100">Log in <span aria-hidden="true">&rarr;</span></a>
                            </div>
                        </nav>
                    </header>
            
                    <div class="relative isolate px-6 pt-14 lg:px-8">
                        <div class="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80" aria-hidden="true">
                        
                        </div>
                        <div class="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
                         
                            <div class="text-center">
                                <h1 class="text-4xl font-bold tracking-tight text-gray-100 sm:text-6xl">Africa Beats</h1>
                                <p class="mt-6 text-lg leading-8 text-gray-100">Os teus artistas favoritos e os teus amigos na mesma plataforma</p>
                            </div>
                        </div>
                        <div class="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]" aria-hidden="true">
                            <div class="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]" ></div>
                        </div>
                    </div>
            </div>
    );
   
}