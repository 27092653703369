import React from "react";

import izenuAPI from '../../services/izenuAPI';
import { BarChart } from '@mui/x-charts/BarChart';
import { PieChart } from "@mui/x-charts";

const month = ["Janeiro","Fevereiro","Março","Abril","Maio","Junho","Julho","Agosto","Setembro","Outubro","Novembro","Dezembro"];

const d = new Date();

export default function DashboardStreams(){
    
    const [data, setData] = React.useState(null);
    
    const [countries, setCountries] = React.useState(null);
    const [genres, setGenres] = React.useState(null);

    const fetchData = React.useCallback(async ()=>{
        try{
            const {data:r} = await izenuAPI.get('https://www.izenu.ao/api/admin/dashboard/fetchDashboardStreamData.php');
            setData(r);
            //console.log(r.streams_by_country.length);
            setCountries(r.streams_by_country);
            setGenres(r.streams_by_genre);
            //setDataMonths(r.months_data);
        }catch(err){
            console.log(err);
        }
        
    }); 

    React.useEffect(()=>{
        fetchData();
    },[]);

    if(data==null){
        return (<div>
                </div>)
    }
    return (<div className="p-10">
                <h1 className="text-left font-bold">Dashboard Streams</h1>
                <div className="p-10 flex flex-row space-x-4">

                    <div className="relative flex-1 gap-2 border py-5 px-5 rounded-lg">
                        <span className="font-bold">
                            Total de streams
                        </span>
                        <h1 className="text-4xl font-extrabold"></h1>
                        
                    </div>

                    <div className="relative flex-1 gap-2 border py-5 px-5 rounded-lg">
                        <span className="font-bold">
                            Streams por região
                        </span>
                        <h1 className="text-4xl font-extrabold"></h1>
                        
                    </div>

                    <div className="relative flex-1 gap-2 border py-5 px-5 rounded-lg">
                        <span className="font-bold">
                            Streams por genero
                        </span>
                        <h1 className="text-4xl font-extrabold"></h1>
                
                    </div>
                    

                </div>
                <div className="p-10 flex flex-row space-x-4">

                    <div className="relative flex-1 gap-2 border py-5 px-5 rounded-lg">
                        <span className="font-bold">{data.total_streams}</span>
                    </div>

                    <div className="relative flex-1 gap-2 border py-5 px-5 rounded-lg">
                        {countries.map((e) => {
                
                            return (<div className="flex flex-row justify-between">
                                        <span className="font-bold">{e.country}</span> <span>{e.total}</span>
                                    </div>)  
                        })}
            
                        
                    </div>

                    <div className="relative flex-1 gap-2 border py-5 px-5 rounded-lg">
                        {genres.map((e)=> {
                            return (<div className="flex flex-row justify-between">
                                        <span className="font-bold">{e.genre}</span><span>{e.streams}</span>
                                    </div>)  
                        })}
                    </div>


                </div>
            </div>)

}
