import React from "react";

import izenuAPI from '../../services/izenuAPI';
import { BarChart } from '@mui/x-charts/BarChart';
import { PieChart } from "@mui/x-charts";

const month = ["Janeiro","Fevereiro","Março","Abril","Maio","Junho","Julho","Agosto","Setembro","Outubro","Novembro","Dezembro"];

const d = new Date();

const m3 = month[d.getMonth() - 2];
const m2 = month[d.getMonth() - 1];
const m1 = month[d.getMonth()];

export default function DashboardOperations(){
    
    const [data, setData] = React.useState(null);
    const [dataMonths, setDataMonths] = React.useState(null);
    const fetchData = React.useCallback(async ()=>{
        try{
            const {data:r} = await izenuAPI.get('https://www.izenu.ao/api/admin/dashboard/fetchDashboardData.php');
            setData(r);
            setDataMonths(r.months_data);
        }catch(err){
            console.log(err);
        }
        
    }); 

    React.useEffect(()=>{
        fetchData();
    },[]);

    if(data==null){
        return (<div>
                </div>)
    }
    return (<div className="p-10">
                <h1 className="text-left font-bold">Dashboard operações</h1>
                <div className="p-10 flex flex-row space-x-4">

                    <div class="relative flex-1 gap-2 border py-20 rounded-lg">
                        <span className="font-light">
                            Total de utilizadores
                        </span>
                        <h1 className="text-4xl font-extrabold">{data.users}</h1>
                        
                    </div>

                    <div class="relative flex-1 gap-2 border py-20 rounded-lg">
                        <span className="font-light">
                            Total de artistas
                        </span>
                        <h1 className="text-4xl font-extrabold">{data.artists}</h1>
                        
                    </div>
                    <div class="relative flex-1 gap-2 border py-20 rounded-lg">
                        <span className="font-light">
                            Total de criadores
                        </span>
                        <h1 className="text-4xl font-extrabold">{data.creators}</h1>
                
                    </div>
                    <div class="relative flex-1 gap-2 border py-20 rounded-lg">
                        <span className="font-light">
                            Total de ouvintes
                        </span>
                        <h1 className="text-4xl font-extrabold">{data.listeners}</h1>
                       
                    </div>

                </div>
                <div className="p-10 flex flex-row space-x-4">
                         <div className="relative flex-1 gap-2 border py-5 rounded-lg">
                            <h1 className="font-bold">
                                Utilizadores nos ultimos 3 meses
                            </h1>
                         </div>
                         <div className="relative flex-1 gap-2 border py-5 rounded-lg">
                            <h1 className="font-bold">
                                Utilizadores
                            </h1>
                         </div>
                </div>
                <div className="p-10 flex flex-row space-x-4">

                    <div className="relative flex-1 gap-2 border py-20 rounded-lg">
                    <BarChart
                    
                        series={[
                            { data: [dataMonths.creators.m3, dataMonths.creators.m2, dataMonths.creators.m1] },
                            { data:  [dataMonths.artists.m3, dataMonths.artists.m2, dataMonths.artists.m1] },
                            { data: [dataMonths.listener.m3, dataMonths.listener.m2, dataMonths.listener.m1] },
                          
                        ]}
                        height={290}
                        xAxis={[{ data: [m3, m2, m1], scaleType: 'band' }]}
                        margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
                        />
                    </div>

                    <div className="relative flex-1 gap-2 border py-20 rounded-lg">
                        <PieChart
                           
                            series={[
                                {
                                data: [
                                    { id: 0, value: data.artists, label: 'Artistas' },
                                    { id: 1, value: data.creators, label: 'Criadores' },
                                    { id: 2, value: data.listeners, label: 'Ouvintes' },
                                ],
                               
                                },
                            ]}
                            />
                    </div>
                </div>
            </div>)

}
