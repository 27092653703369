import { useEffect, useState } from "react";
import axios from 'axios';
import izenuAPI from "../../services/izenuAPI";
import { IoIosWarning } from 'react-icons/io';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

import './styles/list.scss';

const ListBugReports = () => {
  const [bugs, setBugs] = useState([]);

  useEffect(() => {
    async function getReports() {
      const { data: r } = await izenuAPI.get(
        `https://www.izenu.ao/api/report/bugs/list.php`
      );
     
      setBugs(r);
    }

    getReports();
  }, []);

  return (
    <>
      <header className="header">
        <h1 className="text-white">Bugs no aplicativo</h1>
      </header>

      <main className="reports-list">

        {bugs.length > 0?
          bugs.map((report) => {
            return (
              <div key={report.id} className="report">
                <header>
                  <IoIosWarning size={30} color="#000" />
                </header>

                <main>
                  <Link to={`/bug-reports/${report.id}`}>
                    {report.subject}
                  </Link>

                  <p>{report.description}</p>
                </main>

                <span>
                  {format(new Date(report.created_at), 'dd/MM/yyyy')}
                </span>
              </div>
            );
          })
        :
          <div>
            Sem denuncias de bugs
          </div>
        }
        
      </main>
    </>
  );
}

export default ListBugReports;
