import { useState, useEffect, useCallback } from 'react';
import '../../Styles/ArtistRoot.css';

import COUNTRIES from '../../utils/Countries';
import GENRES from '../../utils/Genres';

import { useParams } from 'react-router-dom';
import {AiOutlinePlusCircle} from 'react-icons/ai';
import {BiSolidPlaylist} from 'react-icons/bi';
import { Formik} from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import {AiOutlineDelete} from 'react-icons/ai';

const SONGS  = [
    {name:'Etiene Adriano', title:'hello world', cover: '', user_id:1},
    {name:'Etiene Adriano', title:'hello world', cover: '', user_id:1},
    {name:'Etiene Adriano', title:'hello world', cover: '', user_id:1},
    {name:'Etiene Adriano', title:'hello world', cover: '', user_id:1},
    {name:'Etiene Adriano', title:'hello world', cover: '', user_id:1},
    {name:'Etiene Adriano', title:'hello world', cover: '', user_id:1},
 
];


export default function Playlist(){
    const [music, setMusic] = useState([]);
    const [playlist, setPlaylist] = useState([]);
    const [showModal, setShowModal] = useState(false);
    let { playlist_id } = useParams();
    const removeFromPlaylist = useCallback((id)=>{
        const r = window.confirm('Tem certeza que deseja remover?') || true;
            if(r){
                alert('Musica removida ' + id);
            }
    });
    const listPlaylist = useCallback(async(id)=>{
        try{
            const distributor_id = 1;
            const playlist_id = id;
            const END_POINT = `https://www.izenu.ao/api/distributor/listPlaylistMusic.php?distributor_id=${distributor_id}&playlist_id=${playlist_id}`;
            const {data:r} = await axios.get(END_POINT);
            setMusic(r.musics);
            setPlaylist(r.playlist);

        }catch(err){
            console.log(err);
        }
        
    },[playlist_id]);
    const makeAlbum = useCallback(async ()=>{
        try{
           
            const END_POINT = `https://www.izenu.ao/api/distributor/updatePlaylist.php`;
            const {data:r} = await axios.post(END_POINT, {
                type: 'album',
                playlist_id: playlist_id,
            });
            if(r.response){
                alert('Sucesso!');
            }else{
                alert('Erro, tenta mais tarde');
                console.log(r);
            }

        }catch(err){
            console.log(err);
        }

    },[playlist_id]);
    useEffect(()=>{
     listPlaylist(playlist_id);
    },[]);

    return( <>
                <div style={{display:'flex', flexDirection:'row'}}>   
                    <h1 style={{textAlign:'left', flex:1, marginBottom:20}}>Playlist</h1>
                    <button  className='btn-grey'>
                        <AiOutlinePlusCircle /> Adicionar playlist
                    </button>
                </div>
                <div style={{height: '100%'}} className="content-container">
                        <div style={{width:'100%', height:'auto',padding:40, borderRadius:20,backgroundColor:'#fafafa', display:'flex',alignItems:'center', flexDirection:'column', justifyContent:'center'}}>
                        
                        <div style={{display:'flex', flexDirection:'column'}}>
                            <div style={{marginRight:9, display:'flex', flexDirection:'row'}}>
                                {playlist != null?(
                                    <div style={{width:225, height: 225, marginRight:10}}>
                                        <img src={playlist.cover} style={{width:225, height:225}}/>
                                    </div>
                                ):(
                                    <div style={{width:225, height:225, display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:"#ddd"}} className='box-shadow'>
                                        <BiSolidPlaylist size={60} color="#666"/>
                                    </div>    
                                )}
                                
                                <div style={{justifyContent:'space-between', alignItems:'flex-start', flexDirection:'column', display:'flex'}}>
                                    <h1>{playlist!=null?playlist.title:(<span>error</span>)}</h1>
                                    <button className='btn-blue-round' onClick={()=>setShowModal(true)}>
                                        <h3 style={{fontWeight:400, color:'#fff'}}>Transformar em um album?</h3>
                                    </button>
                                </div>
                            </div>

                            <div style={{marginTop:20}}>
                                {music!= null?(
                                    <div>
                                        {music.map((e, i)=>{
                                            return (<div className="playlistItem"  style={{display:'flex', borderWidth:1, borderColor:'red', marginBottom:0, flexDirection:'row', alignItemsContent:'center', height:60}}>
                                                        <div style={{width:60, height:60, marginRight:10}}>
                                                        {playlist != null?(
                                                            <div style={{width:45, height: 45, marginRight:10}}>
                                                                <img src={playlist.cover} style={{width:45, height:45, borderRadius:4}}/>
                                                            </div>
                                                        ):(
                                                            <div style={{width:45, height:45, display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:"#ddd"}} className='box-shadow'>
                                                                <BiSolidPlaylist size={60} color="#666"/>
                                                            </div>    
                                                        )}
                                                        </div>
                                                        <div style={{display:'flex', flex:1, textAlign:'left', justifyContent:'center', flexDirection:'column', }}>
                                                            <h5>{e.title}</h5>
                                                            <h5 style={{fontWeight:400}}>{e.name || e.singer}</h5>
                                                        </div>
                                                        <button onClick={()=> removeFromPlaylist(i)} className='button'>
                                                            <AiOutlineDelete size={30} color="#172b4d"/>
                                                        </button>
                                                    </div>)
                                        })}
                                    </div>
                                ):(
                                    <div style={{justifyContent:'center', alignItems:'center', display:'flex'}}>
                                        <h1 style={{color:'#aaa',fontWeight: 400}}>Lista de reprodução vazia</h1>
                                    </div>
                                )}
                                
                            </div>
                        </div>
                    </div>
                </div>
                {showModal?(
                    <div className="modal">
                        <div className="modalContent">
                            <div style={{display:'flex', flexDirection:'row', justifyContent: 'center', padding:12, alignItems:'center'}}>
                                <h3>Transfomar em um album</h3>
                            </div>
                            <div style={{flex:1, overflowY:'scroll', padding:20, overflowX:'hidden'}}>
                                <p>
                                    Esta ação é irreversivel, após a ação o album será desponivel na aba album
                                </p>
                                <div style={{display:'flex', marginTop:20, justifyContent:'center', alignItems:'center'}}>
                                    <button style={{marginRight:10}} className='btn-blue-round' onClick={()=>makeAlbum()}>
                                        <h4 style={{color:"#fff"}}>Confirmar</h4>
                                    </button>
                                    <button className='btn-grey-round' onClick={()=>setShowModal(false)}>
                                        <h4 style={{color:"#fff"}}>Cancelar</h4>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                ):(<></>)}
                
            </>); 
}