import { useCallback,useContext, useState } from "react";
import '../../Styles/login.css';

import { Formik } from "formik";
import axios from "axios";
import * as Yup from 'yup';
import { useRoutes, useNavigate } from "react-router-dom";
import { UserContext } from "../../Context/UserContext";
import white_logo from "../../assets/logotipo_white.png";

export default function AdminLogin(){
    const {setUser} = useContext(UserContext);
    const [email, setEmail] = useState('');
    const [pwd, setPwd] = useState('');
    const navigate = useNavigate();
    const checkLogin = useCallback((obj)=>{
        try{
           const r = axios.get('https://www.izenu.ao/api/login/checkAdminLogin.php',{params: obj});
            r.then((r)=>{
                const data = r.data;
                if(data.response){
                    setUser(data.user);
                    localStorage.setItem('user', data.user.email);
                    localStorage.setItem('name', data.user.name);
                    localStorage.setItem('id', data.user.user_id);
                    localStorage.setItem('type', 'admin');
                    localStorage.setItem('token', data.token);
                    navigate('/admin');
                    window.location.reload();
                }else{
                    alert('Utilizador não encontrado!');
                   
                }
            }).catch((error)=>{
                console.log(error);
            });
        }catch(error){
            console.log(error);
        };
    });

    return (<div class="bg-black h-full overflow-y-hidden">
                   
        <div class="relative isolate px-6 pt-14 lg:px-8">
           
            <div class="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
                <div class="sm:mx-auto sm:w-full sm:max-w-sm">
                    <img class="mx-auto h-10 w-auto" src={white_logo} alt="Your Company"/>
                    <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-100">Entrar para a conta de admin</h2>
                </div>
            
                <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <Formik
                initialValues={{
                    email:'',
                    pwd:''
                    
                }}
                
                validate={values => {
                    const errors = {};
                    /*if (image == null) {
                        errors.image = 'A capa da música é obrigatoria';
                    }*/
                    return errors;
                }}
            
                onSubmit={(values, { setSubmitting, resetForm }) =>{
                    //console.log('submit');
                    //alert(JSON.stringify(values, null, 2));
                    checkLogin(values);   
                    //esetForm({values:''});
                    setSubmitting(true);
                    //insertMusic(values);
                }}
                validationSchema={Yup.object({
                    email:Yup.string().email().required('Campo obrigatorio'),
                    pwd:Yup.string().min(5, 'No minimo 5 caracteres').max(30    , 'No maximo 255 caracteres'),
                })}
            >
            {({values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting}) =>(
                    <form onSubmit={(e)=>handleSubmit(e)} class="space-y-6" action="#" method="POST">
                        <div>
                            <label for="email" class="block text-sm font-medium leading-6 text-gray-100">Endereço de email</label>
                            {errors.email && touched.email ? (<span style={{color:'#E5004D', fontSize:13}}> {errors.email}</span>) : null}
                            <div class="mt-2">
                                <input id="email" name="email" onChange={handleChange} onBlur={handleBlur} type="email" placeholder="Introduz o email" autocomplete="email" required class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                            </div>
                            </div>
                        <div>
                        <div class="flex items-center justify-between">
                            <label for="password" class="block text-sm font-medium leading-6 text-gray-100">Palavra passe</label>
                            {errors.pwd && touched.pwd ? (<span style={{color:'#E5004D', fontSize:13}}> {errors.pwd}</span>) : null}
                        </div>
                            <div class="mt-2">
                                <input id="password" name="pwd" onChange={handleChange} onBlur={handleBlur} type="password" placeholder="Introduz a palavra passe" autocomplete="current-password" required class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                            </div>
                        </div>
                
                        <div>
                            <button type="submit" class="flex w-full justify-center rounded-md bg-amber-600 px-3 py-1.5 text-sm font-semibold leading-6 text-gray-100 shadow-sm hover:bg-amber-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Entrar</button>
                        </div>
                    </form>)}
                    </Formik>
                    <p class="mt-10 text-center text-sm text-gray-500">
                        <a href="/" class="font-semibold leading-6 text-amber-600 hover:text-amber-500">Voltar a pagina principal</a>
                    </p>
                </div>
            </div>
            <div class="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]" aria-hidden="true">
                <div class="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]" ></div>
            </div>
        </div>
</div>
            );
} 