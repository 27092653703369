
import '../../Styles/App.css';
import '../../Styles/Admin.css';
import logo from '../../Images/logo.png';
import opt from '../../Images/option-svgrepo-com.svg';

import { useCallback,useState, useEffect } from 'react';

import axios from 'axios';
const token = localStorage.getItem('token');
const izenuApi = axios.create();
izenuApi.defaults.headers.Authorization = 'Bearer ' + token;

export default function PostsReports(){
    const [profiles, setProfiles] = useState([]);
    const [showModal, setShowModal] = useState(false);
    
    const logout = useCallback(()=>{
        localStorage.clear();
        window.location.href = '/home';
       //console.log(localStorage.get('user'));
    });

    const fetchPosts = useCallback(async ()=>{
        
        console.log('token '  + token);
        try{    
          
            const {data: r} = await izenuApi.get('https://www.izenu.ao/api/report/postReports.php');
            setProfiles(r.response);
            console.log(r.response);
        }catch(err){
            console.log(err);
        };
        //https://img.gs/pqgzqcgmgv/quality=low/https://dpagz47n4g1lw.cloudfront.net/users/".$value['author_id']."/posts/".$folder."/".$file['file']
    });
    useEffect(()=>{
        fetchPosts();    
    },[]);
    return (<>
        
                    <div style={{padding:40, display:'flex', alignItems:'center', justifyContent:'center'}}>
                        <div className="admin-content">               
                            <div className="profiles-content">
                                {profiles.map((e,i)=>{
                                    return(
                                        <div key={i} className="user-content-container">
                                            <div className="user-photo">

                                            </div>
                                            <div className="user-data">
                                                <div className="text-white user-name">
                                                    {e.name}
                                                </div>
                                                <div className="text-white report-type">
                                                    {e.type}
                                                </div>
                                            </div>
                                            <button onClick={()=>{
                                                console.log('Hello world');
                                            }} className="btn-option">
                                                <img className="svg-image" src={opt}/>
                                            </button>
                                        </div>
                                    )
                                })}
                            </div>

                        </div>
                    </div>
                 
                {showModal?(
                    <div className="opt-modal-wrapper">
                        <div className="opt-modal-container">
                            <div className="opt-modal-content">
                                <div className="user-report-option">

                                </div>
                            </div>
                        </div>
                    </div>
                ):(
                    <></>
                )}
                
            </>)
}