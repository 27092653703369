import { useFormik } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import './styles/update.scss';

const UpdateBugReports = () => {
  const { id } = useParams();

  const [report, setReport] = useState({});

  useEffect(() => {
    async function loadReport() {
      console.log(id);

      const { data } = await axios.get(
        `https://www.izenu.ao/api/report/bugs/find.php`,
        {
          params: {
            id,
          }
        }
      );

      setReport(data);
    }

    loadReport();
  }, [id]);

  const adminId = useMemo(() => {
    const storedValue = localStorage.getItem('id');

    return storedValue ? Number(storedValue) : undefined;
  }, []);

  const handleSubmit = useCallback(async ({ status }, { resetForm }) => {
    try {
      const formData = {
        adminId,
        status,
      };

      await axios.post(
        `http://localhost/izenu-backend/api/report/bugs/update.php`,
        formData,
        {
          params: {
            id,
          }
        }
      );

      alert('Altereação feita!');
    } catch (err) {
      alert('An error ocurred!');
      console.log(err);
    }
  }, [adminId, id]);

  const formik = useFormik({
    initialValues: {},
    onSubmit: handleSubmit,
  });

  return (
    <>
      <header className="header">
        <h1 className="text-white">Ver detalhes do ocorrido</h1>
      </header>

      <form id="form" onSubmit={formik.handleSubmit}>
        <div>
          <div>
            <label htmlFor="status">Status</label>
            <select name="status" id="status" required onChange={formik.handleChange}>
              <option value="" disabled hidden selected>Selecione uma opção!</option>
              <option value="opened">Aberto</option>
              <option value="closed">Fechado</option>
              <option value="solved">Resolvido</option>
              <option value="idle">Inativo</option>
            </select>
          </div>
        </div>

        <div>
          <div>
            <label htmlFor="subject">Assunto</label>
            <input
              type="text"
              disabled
              name="subject"
              id="subject"
              onChange={formik.handleChange}
              defaultValue={report?.subject}
            />
          </div>
        </div>

        <div>
          <div>
            <label htmlFor="description">Descrição do BUG</label>
            <textarea 
              disabled
              name="description"
              id="description"
              onChange={formik.handleChange} 
              defaultValue={report?.description}
              rows={14}
            />
          </div>
        </div>

        <div>
          <button type="submit">Atualizar</button>
        </div>
      </form>
    </>
  )
}

export default UpdateBugReports;
