import '../../Styles/index.css';
/*
import '../Styles/Admin.css';
import '../Styles/Distributor.css';*/
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import izenuAPI from '../../services/izenuAPI';
import { Formik } from 'formik';
import {AiOutlinePlusCircle} from 'react-icons/ai';
const admin_id   = localStorage.getItem('id');
const admin_name = localStorage.getItem('name');

export default function Distributors(){
    const [data, setData] = useState([]);
    const [tab, setTab] = useState(0);
    const [checked, setChecked] = useState(false);

    const fetchDistributors = useCallback( async (data, reset)=>{
        
        try{
            const {data: res} = await izenuAPI.get('https://www.izenu.ao/api/admin/distributor/fetch.php');
            if(res.response){
                setData(res.distributors);
            }
        }catch(error){
            console.log(error);
        }

    });
    useEffect(()=>{
        fetchDistributors();
    },[]);
    const insertDistributor = useCallback( async (data, reset)=>{
        try{
            const {data: res} = await izenuAPI.post('https://www.izenu.ao/api/admin/distributor/insert.php', data);
            if(res.response){
                alert("Distribuidor adicionado com sucesso!");
            }else{
                alert("Erro ao adicionar, tenta mais tarde");
            };
            //Clean form
            reset({values:''});
        }catch(error){
            console.log(error);
        }

    });
    const renderUsers = useCallback(()=>{
        
        if(data.length > 0){
               
                return   (<>{data.map((e,i)=>{
                    
                        return(<div className="border p-2 flex gap-5 flex-row mb-3 items-center  rounded-lg">
                                    <div className="rounded-full bg-gray-300 h-16 w-16">
                                        <svg class="text-gray-500 dark:text-gray-400 h-16 w-16" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                            <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z"/>
                                        </svg>
                                    </div>
                                    
                                    <div>
                                        <h3 className="p-0 m-0 text-left">{e.email}</h3>
                                        <h3 className="p-0 m-0 text-left">{e.phone}</h3>
                                    </div>
                                </div>)
                    })}</>)
                
        }else{
            return (<div className="justify-center items-center  h-full flex">
                    
                        <h1 className="text-grey-500 font-light text-xl">Nenhum distribuidor encontrado</h1>
                        
                    </div>)
        }
       
    },[data]);

    return (<div className="p-10">
        <div className="p-10 flex flex-row space-x-4">
                 <div className="relative flex-1 gap-2 border py-5 rounded-lg">
                    <h1 className="font-bold">
                        Distribuidores
                    </h1>
                 </div>
                 <div className="relative flex-1 gap-2 border py-5 rounded-lg">
                    <h1 className="font-bold">
                        Adicionar distribuidores
                    </h1>
                 </div>
        </div>
        <div className="p-10 flex flex-row space-x-4">
            <div className="relative border rounded-lg flex-1 max-h-96 overflow-y-auto gap-2  p-5">
                {renderUsers()}
            </div>
            <div className="relative flex-1 gap-2 border p-5 max-h-96 overflow-y-auto  rounded-lg">
                <Formik
                    initialValues={{ email: '', pwd: '', nif:'', phone:'', address:'',  iban:'', name:'' }}
                
                    validate={values => {
                
                        const errors = {};
                        //
                        if (!values.email) {
                
                        errors.email = 'Campo obrigatorio!';
                
                        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                
                        errors.email = 'Endereço de email invalido!';
                
                        }
                        if(values.name == ''){
                        errors.name = 'Campo obrigatorio!'
                        }
                        /*if(values.nif == ''){
                        errors.nif = 'Campo obrigatorio!'
                        }*/
                        if(values.phone == ''){
                        errors.phone = 'Campo obrigatorio!'
                        }
                        if(values.address == ''){
                        errors.address = 'Campo obrigatorio!'
                        }
                        /*if(values.iban == ''){
                            errors.iban = 'Campo obrigatorio!'
                        }*/
                        return errors;
                
                    }}
                
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                       
                        //setSubmitting(true);
                        setTimeout(() => {
                            //values.admin_id = admin_id;
                            //values.pwd = "uraaa";
                            insertDistributor(values, resetForm);
                            setSubmitting(false);
                        }, 400);  
                
                    }}
                >
                {({values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting}) => (
                    <form onSubmit={handleSubmit}>
                        <label for="input-group-1" class="block mb-2 text-sm font-medium text-gray-900 text-left">Nome</label>
                        {errors.name && touched.name && errors.name}
                        <div class="relative mb-6">
                            <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                                <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 6.2V5h11v1.2M8 5v14m-3 0h6m2-6.8V11h8v1.2M17 11v8m-1.5 0h3"/>
                                </svg>
                            </div>
                            <input type="text" name="name"  onChange={handleChange} onBlur={handleBlur} value={values.name} id="input-group-1" className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-amber-500 focus:border-amber-500 block w-full ps-10 p-2.5" placeholder="Nome da distribuidora"/>
                        </div>
                        <label for="input-group-1" class="block mb-2 text-sm font-medium text-gray-900 text-left">NIF</label>
                        {errors.nif && touched.nif && errors.nif}
                        <div class="relative mb-6">
                            <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                                <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-width="2" d="M7 17v1a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1a3 3 0 0 0-3-3h-4a3 3 0 0 0-3 3Zm8-9a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
                                </svg>
                            </div>
                            <input  type='text' onChange={handleChange} onBlur={handleBlur} value={values.nif} name="nif" id="input-group-1" className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-amber-500 focus:border-amber-500 block w-full ps-10 p-2.5" placeholder="Número de identificação fiscal"/>
                        </div>
                        <label for="input-group-1" class="block mb-2 text-sm font-medium text-gray-900 text-left">Contacto</label>
                        {errors.phone && touched.phone && errors.phone}
                        <div class="relative mb-6">
                            <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                                <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                    <path d="M7.978 4a2.553 2.553 0 0 0-1.926.877C4.233 6.7 3.699 8.751 4.153 10.814c.44 1.995 1.778 3.893 3.456 5.572 1.68 1.679 3.577 3.018 5.57 3.459 2.062.456 4.115-.073 5.94-1.885a2.556 2.556 0 0 0 .001-3.861l-1.21-1.21a2.689 2.689 0 0 0-3.802 0l-.617.618a.806.806 0 0 1-1.14 0l-1.854-1.855a.807.807 0 0 1 0-1.14l.618-.62a2.692 2.692 0 0 0 0-3.803l-1.21-1.211A2.555 2.555 0 0 0 7.978 4Z"/>
                                </svg>
                            </div>
                            <input  type='text' onChange={handleChange} onBlur={handleBlur} value={values.phone}  name="phone" id="input-group-1" className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-amber-500 focus:border-amber-500 block w-full ps-10 p-2.5" placeholder="Contacto"/>
                        </div>
                        
                        <label for="input-group-1" class="block mb-2 text-sm font-medium text-gray-900 text-left">Email</label>
                        {errors.email && touched.email && errors.email}
                        <div class="relative mb-6">
                            <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                                <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 16">
                                    <path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z"/>
                                    <path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z"/>
                                </svg>
                            </div>
                            <input  type="email" onChange={handleChange} onBlur={handleBlur} value={values.email} name="email" id="input-group-1" className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-amber-500 focus:border-amber-500 block w-full ps-10 p-2.5" placeholder="Email da distribuidora"/>
                        </div>
                        <label for="input-group-1" class="block mb-2 text-sm font-medium text-gray-900 text-left">IBAN</label>
                        {errors.iban && touched.iban && errors.iban}
                        <div class="relative mb-6">
                            <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                                <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h18M6 14h2m3 0h5M3 7v10a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1Z"/>
                                </svg>
                            </div>
                            <input  type="text" onChange={handleChange} onBlur={handleBlur} value={values.iban} name="iban" id="input-group-1" className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-amber-500 focus:border-amber-500 block w-full ps-10 p-2.5" placeholder="Iban da distribuidora"/>
                        </div>
                        
                        <label for="input-group-1" class="block mb-2 text-sm font-medium text-gray-900 text-left">Endereço</label>
                        {errors.address && touched.address && errors.address}
                        <div class="relative mb-6">
                            <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                                <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m4 12 8-8 8 8M6 10.5V19a1 1 0 0 0 1 1h3v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h3a1 1 0 0 0 1-1v-8.5"/>
                                </svg>
                            </div>
                            <input  type="text" onChange={handleChange} onBlur={handleBlur} value={values.address} name="address" id="input-group-1" className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-amber-500 focus:border-amber-500 block w-full ps-10 p-2.5" placeholder="Endereço da distribuidora"/>
                        </div>

                        <label for="input-group-1" class="block mb-2 text-sm font-medium text-gray-900 text-left">Palavra passe</label>
                        {errors.pwd && touched.pwd && errors.pwd}
                        <div class="relative mb-6">
                            <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                                <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m4 12 8-8 8 8M6 10.5V19a1 1 0 0 0 1 1h3v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h3a1 1 0 0 0 1-1v-8.5"/>
                                </svg>
                            </div>
                            <input  type="text" onChange={handleChange} onBlur={handleBlur} value={values.pwd} name="pwd" id="input-group-1" className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-amber-500 focus:border-amber-500 block w-full ps-10 p-2.5" placeholder="Palavra passe"/>
                        </div>
                        

                        <div class="relative mb-6">
                            <div class="flex items-center">
                                <input onClick={()=>setChecked(!checked)} id="link-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-amber-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                <label for="link-checkbox" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Concordar com os nossos termos de utilização</label>
                            </div>
                        </div>
                        <div class="relative mb-6">
                            <button  type={checked?"submit":"button"} class="h-full text-sm p-2 font-medium bg-amber-500 rounded">
                                <span className="text-white">
                                    Adicionar utilizador
                                </span>
                            </button>
                        </div>

                    </form>
                )}                
                </Formik>
            </div>
        </div>
    </div>)
}